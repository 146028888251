<template comments>
    <div
        class="mj-column-per-50 mj-outlook-group-fix"
        style="
            text-align: left;
            direction: ltr;
            display: inline-block;
            vertical-align: top;
            width: 100%;
        "
    >
        <span v-html="msoStart"></span>
        <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            role="presentation"
            style="vertical-align: top"
            width="100%"
        >
            <tbody>
                <tr>
                    <td
                        align="left"
                        vertical-align="middle"
                        style="word-break: break-word"
                    >
                        <slot></slot>
                    </td>
                </tr>
            </tbody>
        </table>
        <span v-html="msoEnd"></span>
    </div>
</template>

<script>
export default {
    name: 'NewsPostColumn',
    data: () => ({
        msoStart: `<!--[if mso | IE]><td class="" style="vertical-align:top;width:350px;"><![endif]-->`,
        msoEnd: `<!--[if mso | IE]></td><![endif]-->`,
    }),
};
</script>

<style>
@media only screen and (min-width: 480px) {
    .mj-column-per-50 {
        width: 50% !important;
        max-width: 50%;
    }
    .mj-column-per-50 .wysiwyg div.ProseMirror p {
        font-size: 1rem;
    }
}
</style>
